import { NgModule, ModuleWithProviders, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { CommonModule } from '@angular/common';

import { 
    ActionBarComponent,
    FormModalComponent
 } from './components';



const SERVICES = [];

const DECLARTAIONS = [
    ActionBarComponent
];

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        ...SERVICES,
    ],
    declarations: [
        ...DECLARTAIONS,
    ]
})
export class bThingsModule {
    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders>{
            ngModule: bThingsModule,
            providers: [
              ...SERVICES,
            ],
        };
    }
}