/**
 * NOT USED FOR THE MOMENT
 */


import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'formModal',
    styleUrls: ['./formModal.component.scss'],
    templateUrl: './formModal.component.html',
})
export class FormModalComponent implements OnInit{
    @Input() title: string;

    constructor(private activeModal: NgbActiveModal){

    }

    ngOnInit(){}

    closeModal(){
        this.activeModal.close();
    }
}