import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class UserService {
  private user: any;
  constructor (){}

  // get the currently logged in user
  getActiveUser(): Observable<any> {
    return Observable.of(this.user);
  }

  setUser(input): void{
    this.user = input;
  }

  getUserActions(workaround): Observable<any> {
    let actionBar =[
      {
        "icon": "nb-plus",
        "contextMenu": [
          {
            "title": "Add User",
            "queryParams": {
              "action": "addUser"
            }
          }
        ],
        "menuTag": workaround + "actionBar.add"
      }
    ];

    let cardActions =[
      {
        "title": "Delete User",
        "queryParams": {
          "action": "deleteUser"
        }
      }
    ];

    let actions = {
      "actionBar": actionBar,
      "cardActions": cardActions
    }
    return of(actions)
  }
}
