import { Injectable,Inject } from '@angular/core';
import { Observable ,of, BehaviorSubject } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class OrderService {
    private _createdOrder = new BehaviorSubject({});
    orderCreated$: any = this._createdOrder.asObservable();

    private host: string
    private order: any;

    constructor(
        @Inject(APP_CONFIG) config: AppConfig,
        private httpClient: HttpClient
    ){
        this.host = config.baseEndpoint;
    }

    // inform all components that the order has been updated
    updatedOrder(order){
        this.order = order;
        this._createdOrder.next(order);
    }

    // Find out if the user has an order which is created, but not yet submitted (show shopping cart)
    getUserCreatedOrder(user){
        let req = this.httpClient.get(this.host + "orders?status=created&orderedby=" + user.uuid);
        req.subscribe(
            data => {
                let temp: any= data;
                if(temp.data){
                    this.updatedOrder(temp.data[0]);
                }
            }
        )

    }

    addOrder(body): Observable<any>{
        return this.httpClient.post(this.host + "orders",body)
            .map(res =>{
                this.updatedOrder(res);
                return res;
            });
    }

    updateOrder(uuid,body): Observable<any>{
        return this.httpClient.put(this.host + "orders/" + uuid, body)
            .map(res => {
                this.updatedOrder(res);
                return res;
            })
    }

    getOrderRecursive(uuid): Observable<any>{
        return this.httpClient.get(this.host + "orders/" + uuid + "?recursive=true")
            .map(res =>res);
    }

    deleteOrder(uuid): Observable<any>{
        return this.httpClient.delete(this.host + "orders/" + uuid)
            .map(res => {
                this.updatedOrder(res);
                return res;
            });
    }

    getOrders(query): Observable<any>{
        return this.httpClient.get(this.host + "orders?" + query)
            .map(res => res);
    }

    checkout(uuid, body): Observable<any>{
        return this.httpClient.post(this.host + "orders/" + uuid + "/checkout",body)
            .map(res =>{
                this.updatedOrder(res);
                return res;
            })
    }

    calculateTotals(lineitems): any{
        let totals = {
            "amount": 0,
            "total": 0
        }
        lineitems.forEach(element => {
            totals.amount = totals.amount + element.amount;
            totals.total = totals.total + element.subtotal;
        });
        return totals;
    }
}