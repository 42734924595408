import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs/observable/of';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { bThingsModule } from './bThings/bThings.module';

import { AnalyticsService } from './utils/analytics.service';

const socialLinks = [
  /*{
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'socicon-github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'socicon-facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'socicon-twitter',
  },*/
];

const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  ...bThingsModule.forRoot().providers,
  ...NbAuthModule.forRoot({
    strategies:[
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: 'https://kimalapi.b-things.com:3300/v1/public/',
        token: {
          class: NbAuthJWTToken,
          key: 'token'
        },
        login: {
          rememberMe: true,
          endpoint: 'login',
          method: 'post',
          redirect: {
            success: '/pages/main'
          }
        },
        logout: {
          endpoint: 'logout',
          method: 'get',
          redirect: {
            success: '/pages/welcome'
          }
        },
        requestPass: {
          endpoint: 'request-password',
          method: 'post',
          defaultErrors: [''],
          defaultMessages: ['']
        },
        resetPass: {
          endpoint: 'reset-password',
          method: 'put',
          resetPasswordTokenKey: 'reset_token_key',
          redirect: {
            success: '/',
            failure: null
          }
        },
        refreshToken: {
          endpoint: 'refresh-token',
          method: 'post',
          redirect: {
            success: null,
            failure: null,
          },
        }
      }),
    ],

    
    forms: {
      login: {
        socialLinks: [],
        strategy: 'email',
      },
      validation: {
        fullName: {
          required: false,
          minLength: 4,
          maxLength: 50,
        },
        firstName: {
          required: true,
          minLength: 4,
          maxLength: 50,
        },
        phoneNumber: {
          required: true,
          minLength: 8,
          maxLength: 50,
        },
        company:  {
          required: true,
          minLength: 4,
          maxLength: 50,
        },
        street:  {
          required: true,
          minLength: 4,
          maxLength: 50,
        },
        city:  {
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        privacy: {
          required: true,
        }
      }
    },
  }).providers,
  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  {
    provide: NbRoleProvider,
    useValue: {
      getRole: () => {
        return observableOf('guest'); // here you could provide any role based on any auth flow
      },
    },
  },
  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
