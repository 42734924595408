import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { GroupService } from '../../../data/groups.service';
import { UserService } from '../../../data/users.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
    selector: 'actionBar',
    styleUrls: ['./actionBar.component.scss'],
    templateUrl: './actionBar.component.html',
})
export class ActionBarComponent implements OnInit, OnDestroy{
    @Input() title: string;
    @Input() actions: any;
    @Input() supressGroup: boolean
    group: any;
    user: any;
    ancestors: any[];
   
    activeGroupSubscription: Subscription;
    ancestorsSubscription: Subscription;
    
    constructor(
        private groupService: GroupService, 
        private userService: UserService
    ){}

    ngOnInit(){
        // required as a failsafe for displaying the group Name
        this.userService.getActiveUser().subscribe(
            data => {
                this.user = data;
            }
        ) 
        // Get the information of the current selected group. This is important to be able to display the group and breadcrumb
        this.activeGroupSubscription = this.groupService.groupActivated$.subscribe(
            data => {
                //console.log("actionBar data",JSON.stringify(data));
                this.group = data;
            }
        );

        this.ancestorsSubscription = this.groupService.ancestorsUpdated$.subscribe(
            
            data => {this.ancestors = data}
        )
    }

    ngOnDestroy(){
        this.activeGroupSubscription.unsubscribe();
        this.ancestorsSubscription.unsubscribe();
    }

    changeGroup(ancestor){
        this.groupService.setGroup(ancestor.uuid);
    }
}