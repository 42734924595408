import { Injectable,Inject } from '@angular/core'
import { Observable, of } from 'rxjs';


@Injectable()
export class StripeService {
  private key = 'pk_test_a5pKS6GXHWMdFmtUCFGdgKLq';

  stripe = Stripe('pk_test_a5pKS6GXHWMdFmtUCFGdgKLq');
  elements = this.stripe.elements();

  constructor() { }

}