import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StripeService } from './stripe.service';
import { UserService } from './users.service';
import { GroupService } from './groups.service';
import { OrderService } from './orders.service';
import { AssetRepositoryService } from './assetRepository.service';
import { SimService } from './sims.service';
import { NetworktreeService } from "./networktree.service";
// To be deleted
import { ElectricityService } from './electricity.service';
import { StateService } from './state.service';
import { SmartTableService } from './smart-table.service';
import { PlayerService } from './player.service';


const SERVICES = [
  StripeService,
  UserService,
  GroupService,
  OrderService,
  AssetRepositoryService,
  SimService,
  NetworktreeService,
  ElectricityService,
  StateService,
  SmartTableService,
  PlayerService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
