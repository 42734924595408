import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NetworktreeService{

    private host: string;

    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) config: AppConfig
    ){
        this.host = config.baseEndpoint;
    }

    getTree(): Observable<any>{
        return this.httpClient.get(this.host + "networktree")
            .map(res => res);
    }
}